import React from "react"

import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import DefaultLayoutSidebar from "../../components/DefaultLayoutSidebar"
import CustomNavTitle from "../../components/CustomNavTitle"

import { theme } from "../../styles/theme"

import illustration2x from "../../images/illustrations/illustration-transfusion-reaction-immediate@2x.png"

const ImmediateActions = ({ location }) => {
  return (
    <Layout>
      <SEO title="Transfusion Reaction Immediate Actions" />

      <CustomNavTitle
        title="Transfusion Reaction"
        destination="/transfusion-reaction/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="max-w-content theme">
          <h2 css={[styles.title, styles.titlePrimary]}>Immediate Actions</h2>

          <ul css={styles.list}>
            <li>Inform medical staff immediately.</li>

            <li>Stop the transfusion.</li>

            <li>Assess and maintain ABC.</li>

            <li>Maintain venous access.</li>

            <li>Check compatibility of component.</li>
          </ul>

          <h3 css={styles.title}>Additional Actions</h3>

          <ul css={styles.list}>
            <li>
              Perform &amp; monitor patient observations:
              <br />
              &emsp;- Temperature.
              <br />
              &emsp;- Pulse &amp; respiration.
              <br />
              &emsp;- Blood pressure.
              <br />
              &emsp;- Urine output.
              <br />
              &emsp;- O₂ saturations.
            </li>

            <li>Review &amp; monitor fluid balance.</li>

            <li>Retain component bag &amp; administration set.</li>

            <li>Confirm positive patient ID.</li>

            <li>Inform transfusion laboratory.</li>

            <li>Document in patient notes.</li>

            <li>Consider additional actions as per local policy.</li>
          </ul>

          <h3 css={styles.title}>Suggested Investigations</h3>

          <ul css={styles.list}>
            <li>Full blood count (FBC).</li>
            <li>Coagulation screen (including fibrinogen).</li>
            <li>Urea and electrolytes.</li>
            <li>Liver function tests (including bilirubin).</li>
            <li>LDH &amp; haptoglobin.</li>
            <li>Repeat group and screen.</li>
            <li>Blood cultures for the patient.</li>
            <li>Urine test for presence of haemoglobin.</li>
          </ul>

          <p className="mt-gap">
            Others (you may consider depending on symptoms).
          </p>

          <ul css={styles.list}>
            <li>Glucose.</li>
            <li>Blood gases.</li>
            <li>Chest X-Ray.</li>
            <li>IgA level.</li>
            <li>Mast cell tryptase.</li>
            <li>Component bag blood culture (in discussion with NHSBT).</li>
          </ul>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

const styles = {
  title: css({
    fontWeight: "bold",
    background: theme.colours.lightGrey,
    color: theme.colours.red,
    fontSize: theme.fzH2,
    padding: theme.gap,
  }),
  titlePrimary: css({
    background: theme.colours.red,
    color: theme.colours.white,
  }),
  list: css({
    ".theme &": {
      marginBottom: theme.gapDouble,
      marginLeft: theme.gapHalf,
    },
  }),
}

export default ImmediateActions
